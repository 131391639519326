<template>
  <b-card-code v-if="$checkPermission('tech-service-mechanic', 'view')"  id="tech-service-mechanic-form" no-body>
    <template v-for="(classificator, index) in classificators">
      <b-form-checkbox
          @change="changeClassificators"
          v-model="form.classificators"
          :value="classificator.id"
          class="custom-control-primary classificator-checkbox"
      >
        {{ classificator.title }}
      </b-form-checkbox>
    </template>
    <div class="mechanicFormAction">
      <b-button
          @click="saveEditing"
          size="lg"
          variant="primary"
      >
        <fa-icon icon="save"/>
        Պահպանել
      </b-button>
    </div>
  </b-card-code>
</template>

<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BFormCheckbox, BButton} from 'bootstrap-vue'
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'
import carsModule from '@/store/cars/cars'

export default {
  name: 'tech-service-mechanic-form',
  props: ['form'],
  components: {
    BCardCode,
    BFormCheckbox,
    BButton
  },
  data () {
    return {
      CARS_STORE_MODULE_NAME: 'carsModule',
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {
    classificators() {
      const data = (this.form.newClassificator) ? this.$store.state[this.CARS_STORE_MODULE_NAME].new_classificators : this.$store.state[this.CARS_STORE_MODULE_NAME].classificators
      return data.filter(e => e.status).map(e => ({ title: e.code + '-' + e.name, ...e }))
    },
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.$store.dispatch('checkServerStatus')
    this.getClassificators()
  },
  mounted() {
  },
  methods: {
    getClassificators() {
      console.log(this.form)
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getClassificators')
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getNewClassificators')
    },
    changeClassificators () {
      if (this.form.new) {
        const data = {
          item: this.form,
          user: this.$store.state.user
        }
        // this.$socket.emit('MECHANIC_EDITED_CLASSIFICATORS', data)
      }
      else {
        const data = {
          id: this.form.id,
          classificators: this.form.classificators
        }
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/editCheckupMechanic', data)
        const socketData = {
          item: this.form,
          user: this.$store.state.user
        }
        // this.$socket.emit('MECHANIC_EDITED_CLASSIFICATORS', socketData)
      }
    },

    saveEditing () {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_MECHANIC_EDIT_STATUS', false)
    }
  }
}
</script>
<style lang="scss">
#tech-service-mechanic-form {
  max-height: calc(var(--vh, 1vh) * 100 - 15rem);
  min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0 15px;

  .custom-checkbox {
    margin: 1.5rem 0;
    .custom-control-label {
      font-size: 1.2rem;
      margin-left: 10px;
      width: 100%;

      &::before, &::after {
        width: 25px;
        height: 25px;
      }
    }
  }

  .mechanicFormAction {
    position: fixed;
    bottom: 23px;
    z-index: 99999;
    width: 100%;
    left: 0;
    padding: 10px 0;
    text-align: center;

    button {
      opacity: 1;
    }
  }
}
</style>
