<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="tech-service-mechanic" no-body>


    <template v-if="editing">
      <MechanicForm :form="form"/>
    </template>

    <template v-else>
      <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
      >
        <template #cell(actions)="data">
          <b-button
              @click="openEditForm(data.item)"
              v-if="$checkPermission($options.name, 'update')"
              size="sm"
              variant="primary"
          >
            <fa-icon icon="check-double"/>
            Արատորոշում
          </b-button>
        </template>
        <template #cell(car_model)="data">
          {{ data.value.car.name }}, {{ data.value.name }}
        </template>
        <template #cell(createdBy)="data">
          {{ data.value.name }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
            :label="'Գտնվել է ' + totalRows + ' տող'"
            label-cols="3"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
        >
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </template>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import techServiceModule from '@/store/tech-service/techServiceModule'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import store from '@/store'

import MechanicForm from '@/views/pages/tech-service/MechanicForm'

export default {
  name: 'tech-service-mechanic',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    MechanicForm
  },
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'actions',
          label: 'Գործողություն',
          class: 'text-center',
        },
        {
          key: 'CarRegNo',
          label: 'Համարանիշ',
        },
        {
          key: 'createdBy',
          label: 'Օպերատոր',
        },
      ],
      addForm: false,
      editForm: false,
      formFields: {
        id: '',
        classificators: []
      },
      form: {}
    }
  },
  computed: {

    items() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].mechanic_checkups
    },

    editing () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].mechanic_editing
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },

    validateForm() {
      return this.form.name === ''
    },

    user () {
      return this.$store.state.user
    }
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    this.getCheckups()
  },
  methods: {

    getCheckups() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getMechanicCheckups')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openEditForm(item) {
      this.form = item
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_MECHANIC_EDIT_STATUS', true)
      if (this.form.new) {
        // this.$socket.emit('MECHANIC_EDITING', {
        //   id: item.id,
        //   user: this.user,
        //   company: this.user.company,
        //   CarRegNo: item.CarRegNo,
        // })
      }
    },
  },
}
</script>

<style lang="scss">
#tech-service-mechanic {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 12rem);
    min-height: calc(var(--vh, 1vh) * 100 - 12rem);
  }
}
</style>

